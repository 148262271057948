

		<mat-card class="ux-card border border-light p-0" fxFlex="100">

				<mat-card-content class="content-body" fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxFlex="100">

					<mat-accordion class="border border-light p-0 mt-2" fxFlex="49" [fxFlex.lt-sm]="100" >
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
								fxLayoutAlign="space-between stretch">
								<mat-panel-title class="mb-0 text-white font-weight-bold">Old Values
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="mb-0">
								<div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" *ngIf="oldValuesList"
									fxLayoutAlign="space-between center" fxLayoutAlign.xs="start">
									<div fxFlex="100" fxFlex.xs="100"  class="text-break" *ngFor="let attribute of attributeConfig">
										<!-- <mat-label fxFlex="45" fxFlex.xs="100"> <span>{{item.value[0] | labelTranslate | replaceCharacter:'_':' ' | titlecase}}: </span></mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="item.value[1] && item.value[0] !== 'payment_date' && item.value[0] !== 'amount'" class="font-weight-bold" 
										[class]="(item.value[1].includes(',')?' text-break ':'')"> 
										{{ item.value[0] === 'phone_number' ? (item.value[1] ? formatPhnNumber(item.value[1]) : '-') : (item.value[1] | labelTranslate)}}
										</mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="item.value[1] && item.value[0] === 'payment_date'" class="font-weight-bold" 
										[class]="(item.value[1].includes(',')?' text-break ':'')"> 
										{{(item.value[1] | date:'MM-dd-yyyy HH:mm:ss')}}
										</mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="item.value[1] && item.value[0] === 'amount'" class="font-weight-bold" 
										[class]="(item.value[1].includes(',')?' text-break ':'')"> 
										{{item.value[1] | currency}}
										</mat-label> -->
										<div *ngIf="requestDetails.getDeepData(attribute.name) !== undefined" class="pt-2 pb-2">
											<mat-label fxFlex="55" fxFlex.xs="100" class="font-weight-bold">{{attribute.label}}:</mat-label>
											<span *ngIf="attribute.type === 'text'">{{(requestDetails.getDeepData(attribute.name))}}</span>
											<span *ngIf="attribute.type === 'number'">{{(requestDetails.getDeepData(attribute.name))}}</span>
											<span *ngIf="attribute.type === 'date'">{{(requestDetails.getDeepData(attribute.name) | date:'MM-dd-yyyy')}}</span>
											<span *ngIf="attribute.type === 'currency'">{{(requestDetails.getDeepData(attribute.name) | currency )}}</span>
											<span *ngIf="attribute.type === 'email'">{{(requestDetails.getDeepData(attribute.name) | lowercase)}}</span>
										</div>
									</div>
								</div>
							</mat-card-content>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion class="border border-light p-0 mt-2" fxFlex="49" [fxFlex.lt-sm]="100" >
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
								fxLayoutAlign="space-between stretch">
								<mat-panel-title class="mb-0 text-white font-weight-bold">New Values
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="mb-0">
								<div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="start"
									fxLayoutAlign="space-between center" *ngIf="newValuesList">
									<div fxFlex.xs="100" fxFlex="100" class="text-break" *ngFor="let attribute of attributeConfig">
										<!-- <mat-label fxFlex="45" fxFlex.xs="100" fxLayoutAlign="start baseline"> <span>{{item.value[0] | labelTranslate | replaceCharacter:'_':' ' | titlecase}}: </span></mat-label>
										<mat-label *ngIf="item.value[1] && item.value[0] !== 'payment_date' && item.value[0] !== 'amount'" fxFlex="55" fxFlex.xs="100" class="font-weight-bold">
											{{ item.value[0] === 'phone_number' ? (item.value[1] ? formatPhnNumber(item.value[1]) : '-') : (item.value[1] | labelTranslate)}}
										</mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="item.value[1] && item.value[0] === 'payment_date'" class="font-weight-bold" 
										[class]="(item.value[1].includes(',')?' text-break ':'')"> 
										{{(item.value[1] | date:'MM-dd-yyyy')}}
										</mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="item.value[1] && item.value[0] === 'amount'" class="font-weight-bold" 
										[class]="(item.value[1].includes(',')?' text-break ':'')"> 
										{{item.value[1] | currency}}
										</mat-label>
										<mat-label *ngIf="!item.value[1]" fxFlex="55" fxFlex.xs="100" class="font-weight-bold">-</mat-label> -->
										<div *ngIf="requestDetails.getDeepData(attribute.name) !== undefined" class="pt-2 pb-2">
											<mat-label fxFlex="55" fxFlex.xs="100" class="font-weight-bold">{{attribute.label}}:</mat-label>
											<span *ngIf="attribute.type === 'text'">{{(requestDetails.getDeepData(attribute.name))}}</span>
											<span *ngIf="attribute.type === 'number'">{{(requestDetails.getDeepData(attribute.name))}}</span>
											<span *ngIf="attribute.type === 'date'">{{(requestDetails.getDeepData(attribute.name) | date:'MM-dd-yyyy')}}</span>
											<span *ngIf="attribute.type === 'currency'">{{(requestDetails.getDeepData(attribute.name) | currency )}}</span>
											<span *ngIf="attribute.type === 'email'">{{(requestDetails.getDeepData(attribute.name) | lowercase)}}</span>
											
										</div>
									</div>
								</div>
							</mat-card-content>
						</mat-expansion-panel>
					</mat-accordion>
				</mat-card-content>
		</mat-card>