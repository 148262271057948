import { TitleCasePipe } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceRequestAdminService } from '@londonhydro/customerservice-api';
import { RequestStatus, ServiceRequest, ServiceRequestAlert, ServiceRequestAudit, ServiceRequestQueue, User } from '@londonhydro/utility-model';
import { AbstractComponent, FileUploaderConfig, UIUtil, UploaderMode } from '@londonhydro/ux-lib';
import { JsonEditorOptions } from 'ang-jsoneditor';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as _ from 'underscore';
import { CsrDataService } from '../../../backend/csr/dataservice/csr-data.service';
import { ErrorLabelPipe } from '../../../framework/shared/service/error-label.pipe';
import { AssignToUserDialogComponent } from '../assign-to-user-dialog/assign-to-user-dialog.component';
import { InputTextDialogComponent } from '../input-text-dialog/input-text-dialog.component';
import { WorkflowConfirmationDialogComponent, WorkflowConfirmationDialogData } from '../workflow-confirmation-dialog/workflow-confirmation-dialog.component';

@Component({
  selector: 'app-form-detail-view',
  templateUrl: './form-detail-view.component.html',
  styleUrls: ['./form-detail-view.component.scss']
})
export class FormDetailViewComponent extends AbstractComponent implements OnInit, OnDestroy {

  formAttributeConfig: any[] = [{ name: 'requestNo', label: 'Request No', type: 'string', group: 'request' },
  { name: 'form.displayName', label: 'Form Name', type: 'formDisplayName', group: 'request', width: 100, exceptionalFormToTranslate: 'cancel-my-account' },
  { name: 'requestedOn', label: 'Requested On', type: 'date', group: 'request' },
  { name: 'dueDate', label: 'Due Date', type: 'shortDate', group: 'request' },
  { name: 'assignedUser.fullName', label: 'Assigned To', type: 'assignedUser', group: 'request', default: 'Unassigned' },
  { name: 'assignedOn', label: 'Assigned On', type: 'date', group: 'request' },
  {
    name: 'statusObject.resourceLabel', label: 'Status', type: 'string', group: 'request', translate: true,
    dataClass: true, dataValue: 'status'
  },
  { name: 'completedDate', label: 'Completed Date', type: 'date', group: 'request' },
  { name: 'requestOrigin', label: 'Application', type: 'string', group: 'request', translate: true },
  { name: 'requestDetails', label: 'Request Info', type: 'json', group: 'none' },
  { name: 'uploadedFiles', label: 'Uploaded Files', type: 'custom', group: 'none' },
  {
    name: 'fullName', label: 'Name', type: 'string',
    class: 'fullName', group: 'form'
  },
  { name: 'emailAddress', label: 'Email Address', type: 'string', group: 'form' },
  {
    name: 'addressForView', label: 'Service Address', type: 'string',
    class: 'address', group: 'form'
  },
  {
    name: 'requestDetails.account_information.phoneNumber', label: 'Phone Number', type: 'string',
    class: 'phoneNumber', group: 'form'
  },
  {
    name: 'requestDetails.account_information.oavilleHydroAccount', label: 'Account',
    type: 'string', class: 'oakvilleHydroAccount', group: 'form'
  },
  {
    name: 'requestDetails.account_information.profileAccount', label: 'Account',
    type: 'string', class: 'oakvilleHydroAccount', group: 'form'
  },
  {
    name: 'requestDetails.current_account_with_my_oakville_hydro_profile.accountNumber', label: 'Account',
    type: 'string', class: 'oakvilleHydroAccount', group: 'form'
  },
  {
    name: 'requestDetails.current_account_with_my_profile.accountNumber', label: 'Account',
    type: 'string', class: 'oakvilleHydroAccount', group: 'form'
  },
  {
    name: 'accountId', label: 'Account',
    type: 'string', class: 'userAccountId', group: 'form', translate: false
  },
  {
    name: 'account', label: 'Account',
    type: 'string', class: 'newUserAccountId', group: 'form', translate: false
  },
  {
    name: 'requestDetails.additional_information.blanketContract', label: 'Continuing Service Agreement / Blanket Selection', type: 'string',
    class: 'blanketContract', group: 'additional', translate: true
  },
  {
    name: 'requestDetails.additional_information.continuousAgreement', label: 'Continuous Service Agreement', type: 'string',
    class: 'continuousAgreement', group: 'additional', translate: true
  },
  {
    name: 'requestDetails.banking_information.paymentPlan', label: 'PAP Selection',
    type: 'string', class: 'paymentPlan', group: 'additional', translate: true
  },
  {
    name: 'bankingInfoAccountDetails', label: 'Branch Transit Number / Bank Institution Number / Bank Account Number',
    type: 'bankingInfo', class: 'branchOrTransit', group: 'additional', translate: false
  },
  {
    name: 'bankingInfoDoc', label: 'Document',
    type: 'bankingInfoDoc', class: 'branchOrTransit', group: 'additional', translate: false
  },
  {
    name: 'requestDetails.account_to_be_linked.account', label: 'Account to be linked',
    type: 'string', class: 'accountToBeLinked', group: 'additional', translate: false
  },
  {
    name: 'requestDetails.account_to_be_linked.linking-accounts.address', label: 'Address',
    type: 'string', class: 'address', group: 'additional', translate: false
  },
  {
    name: 'landlordOrOwnerPhoneNumber', label: 'Landlord OrOwner Phone Number',
    type: 'string', class: 'landlordOrOwnerPhoneNumber', group: 'additional', translate: false
  },
  {
    name: 'lawyerFullName', label: 'Lawyer\'s Full Name',
    type: 'string', class: 'lawyerFullName', group: 'additional', translate: false
  },
  {
    name: '.lawyerCompanyName', label: 'Lawyer\'s Company Name',
    type: 'string', class: 'lawyerCompanyName', group: 'additional', translate: false
  },
  {
    name: 'lawyerPhoneNumber', label: 'Lawyer\'s Phone Number',
    type: 'string', class: 'lawyerPhoneNumber', group: 'additional', translate: false
  },
  {
    name: 'legalClosingOrLeaseEndDate', label: 'Legal Closing Or Lease End Date',
    type: 'date', class: 'legalClosingOrLeaseEndDateName', group: 'additional', translate: false
  },
  {
    name: 'ownerType', label: 'Owner Type',
    type: 'string', class: 'ownerType', group: 'additional', translate: false
  }
    ,
  {
    name: 'landlordOrOwnerFullName', label: 'Landlord Or Owner\'s Full Name',
    type: 'string', class: 'landlordOrOwnerFullName', group: 'additional', translate: false
  },
  {
    name: 'forwarding_full_address', label: 'Forwarding Address',
    type: 'string', class: 'forwarding_full_address', group: 'additional', translate: false
  },
  {
    name: 'requestedByFirstName', label: 'Requested By',
    type: 'string', class: 'requestedByFirstName', group: 'additional', translate: false
  }
    ,
  {
    name: 'paymentDate', label: 'Payment Date',
    type: 'date', class: 'paymentDate', group: 'additional', translate: false
  }
    ,
  {
    name: 'paymentAmount', label: 'Payment Amount',
    type: 'currency', class: 'paymentAmount', group: 'additional', translate: false
  }
    ,
  {
    name: 'oneTimePaymentType', label: 'One-Time Payment Type',
    type: 'string', class: 'oneTimePaymentType', group: 'additional', translate: false
  }
    ,
  {
    name: 'referenceFromBank', label: 'Reference # From Bank',
    type: 'string', class: 'referenceFromBank', group: 'additional', translate: false
  }
    ,
 
  {
    name: 'confirmOwnership', label: 'Confirmation Of Ownership',
    type: 'string', class: 'userAccountId', group: 'additional', translate: true
  },
  {
    name: 'paymentInstallments', label: 'Payment Terms',
    type: 'array', class: 'paymentInstallments', group: 'paymentTerms', translate: false,
    subAttributeListConfig: [{ name: 'paymentAmount', label: 'Payment Amount', type: 'currency' },
    { name: 'paymentTerms', label: 'Payment Type', type: 'string' },
    { name: 'paymentDate', label: 'Payment Date', type: 'date' }]
  },
  {
    name: 'totalAmount', label: 'Total Amount',
    type: 'currency', class: 'totalAmount', group: 'paymentTerms', translate: false
  },
  ];
  formDetails: ServiceRequestQueue;
  private requestDetails: {};
  localtz = moment.tz.guess();
  isFormOriginMyaccount = false;
  jsonViewerOption: JsonEditorOptions;
  exceptionalPAPformsForBillingCheck: string[] = ['apply-for-pre-authorized-payment', 'change-pre-authorized-payment-form', 'cancel-pre-authorized-payment-form'];
  viewMode = UploaderMode.VIEWER;
  fileCategories: Array<any>;
  fileListWithCategory: any;
  fileViewerConfig = {};
  showFileViewer = false;
  reviewMode = false;
  inpReviewComment: string;
  requestAlerts: ServiceRequestAlert[];
  auditReviews: ServiceRequestAudit[];
  masterForm: ServiceRequest;
  assignedToMe = false;
  private modalDataLoaded = false;
  superAccountUser = null;
  uiTimezone: string;
  gMemberList: User[];
  formModified = false;
  skipEligibleStatus = ['CIS', 'BILLING', 'BLANKET'];
  skipDisabledStatus = ['EXCEPTION', 'COMPLETED', 'CANCELLED'];
  skipBtnEnabled = false;
  formHasGrpConflict = null;

  constructor(injector: Injector, public dialogRef: MatDialogRef<FormDetailViewComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,
    private titleCasePipe: TitleCasePipe, private srAdminService: ServiceRequestAdminService, private toastr: ToastrService,
    private matDialog: MatDialog, private translatePipe: ErrorLabelPipe, private csrDataService: CsrDataService) {
    super(injector);
    this.modalDataLoaded = false;

    if (modalData) {
      this.formDetails = modalData.formdata;
      this.gMemberList = modalData.gMemberList;
      this.formHasGrpConflict = modalData.formHasGrpConflict;
      this.superAccountUser = modalData.superAccountUser;
      this.masterForm = this.formDetails.form;
      this.isFormOriginMyaccount = this.formDetails['requestOrigin'] === 'myaccount' ? true : false;
      this.populateFormDetails();
      this.populateModalData();
    }
    this.uiTimezone = moment(new Date()).tz(UIUtil.UiTimeZone).format('z');
    console.log('UI Timezone', this.uiTimezone);
  }

  populateFormDetails(): void {
    for (let element in this.formDetails) {
      if (element === 'requestDetails' && this.formDetails[element]) {
        //report -a-payment
        if (this.formDetails[element]['one_tme_payment_info'] && this.isFormOriginMyaccount) {
          this.formDetails['paymentDate'] = this.formDetails[element]['one_tme_payment_info']['paymentDate'] || '';
          this.formDetails['oneTimePaymentType'] = this.formDetails[element]['one_tme_payment_info']['oneTimePaymentType'] || '';
          this.formDetails['paymentAmount'] = this.formDetails[element]['one_tme_payment_info']['paymentAmount'] || '';
          this.formDetails['referenceFromBank'] = this.formDetails[element]['one_tme_payment_info']['referenceFromBank'] || '';
        }
        if(this.formDetails[element]['contact_information3'] && !this.isFormOriginMyaccount){
          this.formDetails['account'] = this.formDetails[element]['contact_information3']['account'] || '';
        }
        //link-multiple-account
        if(this.formDetails[element]['account_to_be_linked'] && !this.isFormOriginMyaccount){
          // this.formDetails['account'] = this.formDetails[element]['account_to_be_linked']['account'] || '';
        }
        //payment-arrangement
        if (this.formDetails[element]['paymentTerms'] && this.isFormOriginMyaccount) {
          this.formDetails['totalAmount'] = this.formDetails[element]['paymentTerms']['totalAmount'] || '';
          this.formDetails['paymentInstallments'] = [];
          this.formDetails['paymentInstallments'].push(...this.formDetails[element]['paymentTerms']);
          console.log('Payment installments::', this.formDetails['paymentInstallments'])
        }

        if (this.formDetails[element]['banking_information'] && this.isFormOriginMyaccount) {
          this.formDetails['bankingInfoAccountDetails'] = '';
          this.formDetails['bankingInfoAccountDetails'] += this.formDetails[element]['banking_information']['branchOrTransit'] ? '' + this.formDetails[element]['banking_information']['branchOrTransit'] + ' / ' : '';
          this.formDetails['bankingInfoAccountDetails'] += this.formDetails[element]['banking_information']['institution'] ? '' + this.formDetails[element]['banking_information']['institution'] + ' / ' : '';
          this.formDetails['bankingInfoAccountDetails'] += this.formDetails[element]['banking_information']['account'] ? '' + this.formDetails[element]['banking_information']['account'] : '';
          this.formDetails['bankingInfoDoc'] = this.formDetails[element]['banking_information']['document'] ? this.formDetails[element]['banking_information']['document'] : '';
          this.formDetails['accountId'] = this.formDetails[element]['accountId'] || '';
        }
        if(this.formDetails[element]['banking_information'] && !this.isFormOriginMyaccount){
          this.formDetails['accountId'] = this.formDetails[element]['accountId'] || '';
        }
        if (this.formDetails[element]['move_out_request_information'] && this.isFormOriginMyaccount) {
          this.formDetails['confirmOwnership'] = this.formDetails[element]['move_out_request_information']['selling'] == '1' ? 'move-out-workflow-confirmed' : (this.formDetails[element]['move_out_request_information']['selling'] === '0' ? 'move-out-workflow-not-confirmed' : '');
          this.formDetails['landlordOrOwnerPhoneNumber'] = this.formDetails[element]['move_out_request_information']['landlordOrOwnerPhoneNumber'] || '';
          this.formDetails['lawyerCompanyName'] = this.formDetails[element]['move_out_request_information']['lawyerCompanyName'] || '';
          this.formDetails['lawyerPhoneNumber'] = this.formDetails[element]['move_out_request_information']['lawyerPhoneNumber'] || '';
          this.formDetails['legalClosingOrLeaseEndDate'] = this.formDetails[element]['move_out_request_information']['legalClosingOrLeaseEndDate'] || '';
          this.formDetails['ownerType'] = this.formDetails[element]['move_out_request_information']['ownerType'] || '';

          this.formDetails['landlordOrOwnerFullName'] = this.formDetails[element]['move_out_request_information']['landlordOrOwnerFirstName'] && this.formDetails[element]['move_out_request_information']['landlordOrOwnerLastName'] ?
            this.formDetails[element]['move_out_request_information']['landlordOrOwnerFirstName'].trim() + ' ' + this.formDetails[element]['move_out_request_information']['landlordOrOwnerLastName'] : (this.formDetails[element]['move_out_request_information']['landlordOrOwnerFirstName'] && !this.formDetails[element]['move_out_request_information']['landlordOrOwnerLastName'] ? this.formDetails[element]['move_out_request_information']['landlordOrOwnerFirstName'].trim() :
              (!this.formDetails[element]['move_out_request_information']['landlordOrOwnerFirstName'] && this.formDetails[element]['move_out_request_information']['landlordOrOwnerLastName'] ? this.formDetails[element]['move_out_request_information']['landlordOrOwnerLastName'] : ''));

          this.formDetails['lawyerFullName'] = this.formDetails[element]['move_out_request_information']['lawyerFirstName'] && this.formDetails[element]['move_out_request_information']['lawyerLastName'] ?
            this.formDetails[element]['move_out_request_information']['lawyerFirstName'].trim() + ' ' + this.formDetails[element]['move_out_request_information']['lawyerLastName'] : (this.formDetails[element]['move_out_request_information']['lawyerFirstName'] && !this.formDetails[element]['move_out_request_information']['lawyerLastName'] ? this.formDetails[element]['move_out_request_information']['lawyerFirstName'].trim() :
              (!this.formDetails[element]['move_out_request_information']['lawyerFirstName'] && this.formDetails[element]['move_out_request_information']['lawyerLastName'] ? this.formDetails[element]['move_out_request_information']['lawyerLastName'] : ''));
        }
        if (this.formDetails[element]['forwarding_address'] && this.isFormOriginMyaccount) {
          this.formDetails['forwarding_full_address'] = this.getFullMoveOutForwardingAddress(this.formDetails[element]['forwarding_address']);
        }
        if (!this.formDetails[element]['forwarding_address'] && this.isFormOriginMyaccount && this.formDetails.formName === 'cancel-my-account') {
          this.formDetails['forwarding_full_address'] = 'Same as previous mailing address';
        }
        if (this.formDetails[element]['contact_information'] && this.isFormOriginMyaccount) {
          this.formDetails['requestedByFirstName'] = this.formDetails[element]['contact_information']['requestedByFirstName'] || '';
        }
        if (this.formDetails[element]['firstName']) {
          this.formDetails['fullName'] = this.formDetails[element]['firstName'] || '';
        }
        if (this.formDetails[element]['lastName']) {
          this.formDetails['fullName'] += this.formDetails[element]['lastName'] || '';
        }
        if (this.formDetails[element]['service_address_segments'] || this.formDetails[element]['service_address']) {
          const serviceAddress = this.formDetails[element]['service_address_segments'] || this.formDetails[element]['service_address']
          this.formDetails['addressForView'] = this.getFullServiceAddress(serviceAddress);
        } else if (this.formDetails[element]['new_service_address']) {
          this.formDetails['addressForView'] = this.getFullServiceAddress(this.formDetails[element]['new_service_address']);
        } else if (this.formDetails[element]['upgrade_service_address']) {
          this.formDetails['addressForView'] = this.getFullServiceAddress(this.formDetails[element]['upgrade_service_address']);
        } else if (this.formDetails[element]['contact_information_customer_owned']) {
          this.formDetails['addressForView'] = this.formDetails[element]['contact_information_customer_owned']['address'];
        } else if (this.formDetails[element]['current_account_with_my_oakville_hydro_profile'] && this.formDetails[element]['current_account_with_my_oakville_hydro_profile']['Service-address']) {
          //this check is meant for link-multiple-accounts form
          this.formDetails['addressForView'] = this.getFullServiceAddress(this.formDetails[element]['current_account_with_my_oakville_hydro_profile']['Service-address']);
        } else if (this.formDetails[element]['current_account_with_my_profile'] && this.formDetails[element]['current_account_with_my_profile']['Service-address']) {
          //this check is meant for link-multiple-accounts form
          this.formDetails['addressForView'] = this.getFullServiceAddress(this.formDetails[element]['current_account_with_my_profile']['Service-address']);
        }
        else {
          // this check is meant for update-account-information form
          _.each(this.formDetails[element], (res, index) => {
            if (index.toString().includes('customer_information')) {
              _.each(res, (data, index) => {
                if (index.toString().includes('address')) {
                  if (data instanceof Object) {
                    this.formDetails['addressForView'] = this.getFullServiceAddress(data);
                  } else {
                    this.formDetails['addressForView'] = data;
                  }
                }
              });
            }
          });
        }
      }
    }
    if ( this.formDetails.requestedOn ) {
        this.formDetails.requestedOn = moment.utc(this.formDetails.requestedOn).toDate();
      }
    if (this.formDetails.assignedOn){
        this.formDetails.assignedOn = moment.utc(this.formDetails.assignedOn).toDate();
      }
    if (this.formDetails.completedDate){
        this.formDetails.completedDate = moment.utc(this.formDetails.completedDate).toDate();
      }
    if (this.formDetails.dueDate){
        this.formDetails.dueDate = moment.utc(this.formDetails.dueDate).toDate();
      }
    console.log('this.formDetails::::', this.formDetails);
  }
  getFullServiceAddress(serviceAddr: any): string {
    let address = '';
    if (serviceAddr) {
      if(serviceAddr.unitNumber == 'None' || serviceAddr.unitNumber == null){
        address += ''
      }
      else{
        address += serviceAddr.unitNumber ? ('Unit # ' + serviceAddr.unitNumber + ', ') : '';
      }
      address += (serviceAddr.streetNumber === null ? '' : serviceAddr.streetNumber)
        + ' ' + serviceAddr.streetName;
      address += serviceAddr.city ? (', ' + serviceAddr.city) : '';
      address += serviceAddr.province ? (', ' + serviceAddr.province) : '';
      address += serviceAddr.postalCode ? (' ' + serviceAddr.postalCode) : '';
    }
    else {
      address = serviceAddr && serviceAddr.unitNumber ? serviceAddr.unitNumber : '';
    }
    return address.length > 0 ? address : null;
  }

  getFullMoveOutForwardingAddress(serviceAddr: any): string {
    let address = '';
    if (serviceAddr) {
      if(serviceAddr.unitNumber == 'None' || serviceAddr.unitNumber == null){
        address += ''
      }
      else{
        address += serviceAddr.unitNumber ? ('Unit # ' + serviceAddr.unitNumber + ', ') : '';
      }
      address += (serviceAddr.streetNumber ? (serviceAddr.streetNumber) : '');
      address += serviceAddr.address ? (' ' + serviceAddr.address) : '';
      address += serviceAddr.city ? (', ' + serviceAddr.city) : '';
      address += serviceAddr.postalOrZIPCode ? (', ' + serviceAddr.postalOrZIPCode) : '';
      address += serviceAddr.provinceOrState ? (', ' + serviceAddr.provinceOrState) : '';
      address += serviceAddr.country ? (', ' + serviceAddr.country) : '';

      if (serviceAddr.sameAsPreviousMailingAddress && serviceAddr.sameAsPreviousMailingAddress === 'Yes') {
        return 'Same as previous mailing address';
      }
    }
    else {
      address = serviceAddr && serviceAddr.unitNumber ? serviceAddr.unitNumber : '';
    }
    return address.length > 0 ? address : null;
  }

  populateModalData(): void {
    if (this.formDetails && this.loginUser && !this.modalDataLoaded) {
      console.log('this.formDetails::::', this.formDetails);
      this.populateAdditionalDetails();
      this.fetchRequestAlerts();
      this.fetchAuditReview();

      const lthis = this;

      this.fileCategories = new Array();
      const uploadedFiles = JSON.parse(this.formDetails.uploadedFiles);
      if (uploadedFiles) {
        const fcats = _.allKeys(uploadedFiles);
        _.each(fcats, (category: string) => {
          if (uploadedFiles[category] != null && !_.isEmpty(uploadedFiles[category])) {
            lthis.fileCategories.push(category);
          }
        });

        this.fileListWithCategory = uploadedFiles;
        _.each(this.fileCategories, (category: string) => {
          lthis.fileViewerConfig[category] = lthis.buildFileUploadConfigAsViewer(this.titleCasePipe.transform(category));
        });
        console.log('this.fileCategories::', this.fileCategories, this.fileListWithCategory);
      }
      this.modalDataLoaded = true;
    }
  }

  private populateAdditionalDetails(): void {
    this.assignedToMe = (this.formDetails.assignedTo === this.loginUser.id);
    if (this.masterForm) {
      this.formDetails.formWorkflow = this.masterForm.workflow;
    }
    this.requestDetails = this.formDetails.requestInfo;
    this.formDetails.requestDetails = this.requestDetails;
    console.log('this.masterForm::::', this.masterForm);
    if (!this.formDetails.form) {
      this.formDetails.form = this.masterForm
    }
    if (this.formDetails.applicableWorkflow) {
      this.skipBtnEnabled = this.formDetails.applicableWorkflow.filter( btn =>
       _.contains(this.skipEligibleStatus, btn)).length &&
       !_.contains(this.skipDisabledStatus, this.formDetails.status) &&
       this.formDetails.nextWorkflowApplicable() !== 'COMPLETED' ? true : false;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.jsonViewerOption = new JsonEditorOptions();
    this.jsonViewerOption.modes = ['code', 'tree', 'view'];
    this.jsonViewerOption.mode = 'view';
    this.jsonViewerOption.enableTransform = false;
    this.inpReviewComment = '';
    this.requestAlerts = null;
  }

  private fetchRequestAlerts(): void {
    this.srAdminService.getServiceRequestAlerts(this.formDetails.requestId).subscribe(
      (res: ServiceRequestAlert[]) => {
        this.requestAlerts = res;
        this.requestAlerts.forEach(element => {
          element.createdDate = moment.utc(element.createdDate).toDate();
        })
      },
      (error: any) => {
        console.log('error getFormsCountByType', error);
      }
    );
  }

  private fetchAuditReview(): void {
    this.srAdminService.getServiceRequestAuditReviews(this.formDetails.requestId).subscribe(
      (res: ServiceRequestAudit[]) => {
        if (res){
        this.auditReviews = res.reverse();
        this.auditReviews.forEach(element => {
          if(element && element.createdDate){
          element.createdDate = moment.utc(element.createdDate).toDate();
          if (element['systemMessage'] && this.isJsonString(element['systemMessage'])) {
            element['systemMessage'] = JSON.parse(element['systemMessage']);
          }
        }
        })
      }
      },
      (error: any) => {
        console.log('error getFormsCountByType', error);
      }
    );
  }

  private buildFileUploadConfigAsViewer(ulabel?: string): FileUploaderConfig {
    const uConfig = new FileUploaderConfig();
    if (ulabel) {
      uConfig.replaceTexts.selectFileBtn = ulabel;
    } else {
      uConfig.replaceTexts.selectFileBtn = '';
    }
    return uConfig;
  }

  isJsonString(str): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  toggleFileViewer(): void {
    this.showFileViewer = !this.showFileViewer;
  }

  toggleReviewMode(): void {
    this.reviewMode = !this.reviewMode;
  }

  saveReviewComment(): void {
    this.srAdminService.addReview(this.formDetails.requestId, this.inpReviewComment).subscribe(
      (res: any) => {
        this.toastr.success('Data updated successfully', 'Success', {
          timeOut: 10000,
        });
        this.inpReviewComment = '';
        this.reviewMode = false;
        this.fetchAuditReview();
      },
      (error: any) => {
        console.log('error getFormsCountByType', error);
        this.toastr.error('Error during data update, please try again later.', 'error', {
          timeOut: 10000,
        });
        this.inpReviewComment = '';
        this.reviewMode = false;
        // this.fetchAuditReview();
      }
    );
  }

  navigateToFormView(): void {
    const formUrl = environment.customerFormsUrl + '/openform/' + this.formDetails.requestId;
    console.log('Navigating to view form:', formUrl);
    window.open(formUrl);
  }

  confirmWorkflowUpdate(status: string): void {
    const statusLabel = this.translatePipe.transform(RequestStatus.getStatus(status).resourceLabel);
    const dialogRef = this.matDialog.open(WorkflowConfirmationDialogComponent, {
      width: '650px',
      data: (new WorkflowConfirmationDialogData('Confirmation for status change to "' + statusLabel + '"', 'Do you want to continue?',
        this.formDetails, this.requestAlerts)),
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      const confirmed = result;
      if (confirmed != null && confirmed === true) {
        this.updateWorkflow(status);
      }
    });
  }

  skipWorkflow(): void{
    this.updateWorkflow(this.formDetails.nextWorkflowApplicable(), null, true);
  }

  /* async */
  updateWorkflow(status: string, remarks?: string, skipNxtWorkflow = false): void {
    const statusLabel = this.translatePipe.transform(RequestStatus.getStatus(status).resourceLabel);
    this.srAdminService.updateWorkflow(this.formDetails.requestId, status, remarks, false, statusLabel, skipNxtWorkflow).subscribe(
      (res: ServiceRequestQueue) => {
        if (this.formDetails.status === res.status) {
          console.log('error in updateWorkflow');
          this.fetchAuditReview();
          this.toastr.error('Error in updating the status to ' + status + '. Please check review details for more information.', 'Error', {
            timeOut: 10000,
          });
        } else {
          this.formDetails = res;
          this.formModified = true;
          this.populateAdditionalDetails();
          this.populateFormDetails();
          this.fetchAuditReview();
          const messageCode = (skipNxtWorkflow ? 'success.message.skip.' : 'success.message.')
            + RequestStatus.getStatus(status).resourceLabel;
          this.toastr.success(this.translatePipe.transform(messageCode) || 'Data updated successfully', 'Success', {
            timeOut: 10000,
          });
        }
      },
      (error: any) => {
        console.log('error updateWorkflow', error);
        this.toastr.error('Error in updating the status to ' + status + '. Please check review details for more information.', 'Error', {
          timeOut: 10000,
        });
      }
    );
  }

  updateWorkflowWithRemarks(status: string): void {
    const dialogRef = this.matDialog.open(InputTextDialogComponent, {
      width: '450px',
      data: { title: 'Raise Exception', remarks: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      const remarks = result;
      if (remarks && remarks != null) {
        this.updateWorkflow(status, remarks);
      }
    });
  }
  assignToMe(): void {
    this.assignRequest(this.formDetails.requestId, this.loginUser.id);
  }
  private assignRequest(formRequestId: any, userId?: string): void {
    formRequestId = [formRequestId];
    this.csrDataService.assignFormRequest(formRequestId, userId).subscribe(
      (res: any) => {
        this.formDetails = res[0];
        this.formModified = true;
        this.populateAdditionalDetails();
        this.populateFormDetails();
        this.fetchAuditReview();
        const successMsg = this.superAccountUser && userId !== this.loginUser.id ? 'Assigned to the designated CSR\'s Queue' : 'Assigned to your Queue';
        this.toastr.success(successMsg, 'Success', {
          timeOut: 10000,
        });
      },
      (error: any) => {
        console.log('error assignRequest', error);
        this.toastr.error('Error during request assign operation', 'Error', {
          timeOut: 10000,
        });
      }
    );
  }

  assignToUserViewer(): void {
    if (this.gMemberList){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'assign-user-modal-component';
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.gMemberList;
    const assignToUserModal = this.matDialog.open(AssignToUserDialogComponent, dialogConfig);
    assignToUserModal.afterClosed().subscribe((response: any) => {
      if (response !== null) {
        console.log('assigned user modal closed::::', response);
        this.assignRequest(this.formDetails.requestId, response);
      }
    });
   }else if (!this.gMemberList && !this.formHasGrpConflict){
        this.toastr.error('Unable to complete assign operation.', 'Error', {
          timeOut: 10000,
        });
   }else if (this.formHasGrpConflict){
      this.toastr.error('Please select requests having same groups !', 'Error', {
          timeOut: 10000,
        });
    }
  }

  unassign(): void {
    this.srAdminService.unassign(this.formDetails.requestId).subscribe(
      (res: any) => {
        this.formDetails = res;
        this.formModified = true;
        this.populateAdditionalDetails();
        this.populateFormDetails();
        this.fetchAuditReview();
        this.toastr.success('Removed from your Queue', 'Success', {
          timeOut: 10000,
        });
      },
      (error: any) => {
        console.log('error getFormsCountByType', error);
        this.toastr.error('Error during unassign operation', 'Error', {
          timeOut: 10000,
        });
      }
    );
  }

  isAdditionalSectionRequired(): boolean {
    const filteredList = _.filter(this.formAttributeConfig, (attribute: any) => {
      return attribute.group === 'additional' && this.formDetails.getDeepData(attribute.name);
    });
    return filteredList && filteredList.length > 0;
  }

  isPaymentTermsSectionRequired(): boolean {
    const filteredList = _.filter(this.formAttributeConfig, (attribute: any) => {
      return attribute.group === 'paymentTerms' && this.formDetails.getDeepData(attribute.name);
    });
    return filteredList && filteredList.length > 0;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
