export const environment = {
  production: false,
  devToolbar: false,
  developmentMode: true,
  xrayMode: true,
  appName: 'myaccountcsr',
  brandingUrl: './branding/branding.json',
  customerFormsUrl: '',
  accountCheckEndpoint :'',

  myAccountUrl: '',

  documentservice: {
    endPoint: ''
  },

  iam: {
    homePage: 'page/login',
    secureHomePage: '/csrapp/home',
    nonSecureURI: ['/iam/login', '/login', '/page/login'],
    loginUrl: '/page/login',
    logoutUrl: '/logout',
    tokenEndPoint: '',
    endPoint: '',
    sessionTokenKey: 'qa-iam-token',
    supportedRoles: ['CSR'],
  },

  customerservice: {
    endPoint: ''    
  },

  accountservice: {
    endPoint: ''    
  },

  adminservice: {
    legacyEndPoint: '',
        endPoint: ''
    
  },

  security: {
    recaptcha: {
      siteKey: 'XXXXX'
    }, 
    google_client_id: {
      id: 'XXXXX'
    }
  },

  googleAnalytics: {
    domain: 'none',
    trackingId: 'UA-30535898-6'
  },

  /*** Need to replace below api calls with utility api angular lib services... ***/
  customerApiEndPoint: '',
  bnpApiEndPoint: '',
  customerSearchApiEndPoint: '',
  postErrorEndpoint:''
};
