<div id="modal-content-wrapper">
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Unlink Customer</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link " type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
				<div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
					<div fxFlex="80">
					   Are you sure you want to unlink the below customer ?
					</div>
				</div>
				<div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start space-between"
					fxLayoutAlign="space-between center">
					<div fxFlex="30" class="p-2">Customer ID:</div>
					<div fxFlex="70" class="p-2 font-weight-bold text-break">
						<span>{{ userData.data.id || '-'}}</span>
					</div>
				</div>
				<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
					fxLayoutAlign.xs="start space-between">
					<div fxFlex="30" class="p-2" *ngIf="userData.data.customerType =='P'">Full Name:</div>
					<div fxFlex="30" class="p-2" *ngIf="userData.data.customerType =='O'">Customer Name:</div>
					<div fxFlex="70" class="p-2 font-weight-bold text-break">
						<span *ngIf="userData.data.customerType =='P'">{{userData.data.fullName}}</span>
						<span *ngIf="userData.data.customerType =='O'">{{userData.data.organization}}</span>
					</div>
				</div>
				<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column"
					fxLayoutAlign.xs="start space-between">
					<div fxFlex="30" class="p-2 white-sp-responsive">Email(s):</div>
					<div fxFlex="70" class="p-2 font-weight-bold text-break">
						<div *ngIf="userData.data.emails && userData.data.emails.length>0" class="">
							<span *ngIf="userData.data.emails.length==1">{{userData.data.emails[0].emailAddress}}</span>
							<ul *ngIf="userData.data.emails.length>1" class="p-0">
								<li *ngFor="let email of userData.data.emails;let i=index" class="font-weight-bold">
				
									<span *ngIf="userData.data.emails.length>1">{{email.emailAddress}}
										{{email.standard ? ' (Standard)' : ''}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end">
					<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
					<button class="btn btn-sm ux-icon-button mr-3"
						data-toggle="tooltip" title="Link Customer Details" (click)="unlinkCustomer()">OK</button>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</section>
</div>