import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'underscore';

@Component({
  selector: 'app-additional-cis-data-viewer',
  templateUrl: './additional-cis-data-viewer.component.html',
  styleUrls: ['./additional-cis-data-viewer.component.scss']
})
export class AdditionalCisDataViewerComponent implements OnInit {
  newValuesList: any;
  oldValuesList: any;
  attributeConfig: any[] = [
  { name: 'requestDetails.newValues.confirmation_number', label: 'Confirmation Number', type: 'number'},
  { name: 'requestDetails.newValues.payment_method', label: 'Payment Method', type: 'text'},
  { name: 'requestDetails.newValues.payment_date', label: 'Payment Date', type: 'date'},
  { name: 'requestDetails.newValues.amount', label: 'Amount', type: 'currency'},
  { name: 'requestDetails.newValues.email_address', label: 'Effective Email', type: 'email'},
  { name: 'requestDetails.newValues.effective_date', label: 'Effective Date', type: 'date'},
  { name: 'requestDetails.newValues.rate_type', label: 'Rate Type', type: 'text'},
  { name: 'requestDetails.newValues.service_id', label: 'Service Id', type: 'text'},
  ];

  @Input() requestDetails;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public userData: any) { }

  ngOnInit(): void {
    this.newValuesList = this.requestDetails.newValues ? Object.entries(this.requestDetails.newValues): [];
    this.oldValuesList = this.requestDetails.oldValues ? Object.entries(this.requestDetails.oldValues): [];
  }

  formatPhnNumber(phnNumber: string): string {
    if (!phnNumber) {
      return '-';
    }
    if (phnNumber.length > 10) {
      return phnNumber;
    }
    else if (phnNumber.length === 10) {
      return phnNumber.substring(0, 3) + '-' + phnNumber.substring(3, 6) + '-' + phnNumber.substring(6);
    }
    else {
      return '-';
    }
  }

  closeModal(): void{
    this.dialogRef.close();
  }
}