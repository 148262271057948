import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup , ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ServiceRequestAdminService } from '@londonhydro/customerservice-api';
import { RequestStatus, ServiceRequest, ServiceRequestQueue, StatisticsProfile, Status, User } from '@londonhydro/utility-model';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';
import { DataFilter } from '../../framework/shared/model/datafilter';
import { FilterRule } from '../../framework/shared/model/filterrule';
import { ErrorLabelPipe } from '../../framework/shared/service/error-label.pipe';
import { environment } from '../../../environments/environment';
import * as _ from 'underscore';
import { SelectionModel } from '@angular/cdk/collections';
import { FileViewerComponent } from '../service-request-dashboard/file-viewer/file-viewer.component';
import { RequestDetailViewComponent } from './request-detail-view/request-detail-view.component';
import { AssignToUserDialogComponent } from '../service-request-dashboard/assign-to-user-dialog/assign-to-user-dialog.component';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const nestedProperty = (data: any, sortHeaderId: string): string | number => {
  return sortHeaderId
    .split('.')
    .reduce((accumulator, key) => accumulator && accumulator[key], data) as | string | number;
};

const sortingDataAccessor = {
  nestedProperty,
};

@Component({
  selector: 'app-service-request-cis-dashboard',
  templateUrl: './service-request-cis-dashboard.component.html',
  styleUrls: ['./service-request-cis-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class ServiceRequestCisDashboardComponent extends AbstractComponent implements OnInit, AfterViewInit {

  formsFilterForm: FormGroup;
  quickSearch = new FormControl();
  formListDS: MatTableDataSource<any> = null;
  showPageLoader = false;
  selectedFilter = 'All';
  @ViewChild('formsPaginator') formsPaginator: MatPaginator;
  searchFilter: string;
  searchFilterList: any;
  groupMemberList = [];
  selectedFilterType = 'Name';
  filterGroups: string[] = ['Name', 'Status'];
  statusList: Status[] = null;
  currentStatusList: Status[] = [RequestStatus.All, RequestStatus.Failed, RequestStatus.Submitted];
  archiveStatusList: Status[] = [RequestStatus.All, RequestStatus.Processed];
  qLinks: StatisticsProfile[];
  tagLinks: StatisticsProfile[];
  selectedQlink: StatisticsProfile;
  selectedTaglink: StatisticsProfile;
  allForms: ServiceRequestQueue[] = null;
  showFullMatTable = false;
  showSideNav = false;
  showDisclaimerText = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('csrTableSort') sort: MatSort;
  @ViewChild('statusSelect') skillSel: MatSelect;
  @ViewChild('allSelected') private allSelected: MatOption;

  editorOpt: JsonEditorOptions;
  displayedColumns: string[];
  formAttributeConfig: any[] = [
  {name: 'rowIndex', label: '#', type: 'rowIndex', class: 'sl'},
  { name: 'requestNo', label: 'Req. No', type: 'detailview', class: 'requestNo' },
  { name: 'form.displayName', label: 'Process', type: 'string', class: 'formName' },
  { name: 'customerId', label: 'Customer ID', type: 'detailview', class: 'customerId' },
  { name: 'accountId', label: 'Acount ID', type: 'detailview', class: 'accountId' },
  /* { name: 'currentServiceAddress', label: 'Service Address', type: 'string', class: 'address' }, */
  { name: 'requestedOn', label: 'Transaction Date', type: 'date', class: 'requestedOn' , dateFormat: 'short'},
  {
    name: 'statusObject.resourceLabel', label: 'Status', type: 'string', translate: true, dataClass: true,
    dataValue: 'status', class: 'status'
  } ,
  { name: 'action', label: 'Manage', type: 'action', class: 'action' },
  /*{ name: 'requestDetails.banking_information.firstName', label: 'First Name', type: 'string', class: 'firstName', subRow: true },
  {
    name: 'requestDetails.banking_information.lastName', label: 'Last Name', type: 'string',
    class: 'lastName', subRow: true
  },
  {
    name: 'requestDetails.account_information.phoneNumber', label: 'Phone Number', type: 'string',
    class: 'phoneNumber', subRow: true
  },
  {
    name: 'requestDetails.account_information.oavilleHydroAccount', label: 'Oakville Hydro Account',
    type: 'string', class: 'oakvilleHydroAccount', subRow: true
  },
  { name: 'completedDate', label: 'Completed Date', type: 'date', subRow: true } */
  ];

  formAttributeConfigMQ: any[] = [
  {name: 'rowIndex', label: '#', type: 'rowIndex', class: 'sl'},
  { name: 'requestNo', label: 'Req. No', type: 'detailview', class: 'requestNo' },
  { name: 'form.displayName', label: 'Process', type: 'string', class: 'formName' },
 /*  { name: 'currentServiceAddress', label: 'Service Address', type: 'string', class: 'address' }, */
  { name: 'requestedOn', label: 'Req. On', type: 'date', class: 'requestedOn' , dateFormat: 'short'},
  {
    name: 'statusObject.resourceLabel', label: 'Status', type: 'string', translate: true,
    dataClass: true, dataValue: 'status', class: 'status'
  },
  { name: 'dueDate', label: 'Due Date', type: 'date', dateFormat: 'shortDate' },
  { name: 'action', label: 'Manage', type: 'action', class: 'action' },
 /*  { name: 'requestDetails.banking_information.firstName', label: 'First Name', type: 'string', class: 'firstName', subRow: true },
  { name: 'requestDetails.banking_information.lastName', label: 'Last Name', type: 'string', class: 'lastName', subRow: true },
  { name: 'requestDetails.account_information.phoneNumber', label: 'Phone Number', type: 'string', class: 'phoneNumber', subRow: true },
  {
    name: 'requestDetails.account_information.oavilleHydroAccount', label: 'Oakville Hydro Account',
    type: 'string', class: 'oakvilleHydroAccount', subRow: true
  },
  { name: 'completedDate', label: 'Completed Date', type: 'date', subRow: true } */
  ];

  tablinks = ['All Requests'];
  activeTab: string;
  advSearchOn = false;
  allMasterForms: ServiceRequest[];
  formMap: Map<string, ServiceRequest>;
  groupMemberMap: Map<string, User[]>;
  expandAllSubRow = false;
  superAccountUser = null;
  selectedDataFilter: DataFilter;
  attributeDataFilter: any;
  attributeDataFilterValueList: string[];
  attributeDataFilterValue: string;
  attributeDataFilterValueMap: Map<string, string> = new Map();
  filterConditions = ['>=', '<=', '>', '<', '=', '!='];
  formDataAttributes: Array<any>;
  localtz = moment.tz.guess();
  roleCategoryMap: Map<string, any> = new Map([['csr', {general: 'csr', super: 'csr_super'}], ['engineering',
   {general: 'engineering', super: 'engineering_super'}]]);
  formHasGrpConflict = null;

  selection = new SelectionModel<any>(true, []);
  archiveSelected = false;
  groupName: string;

  constructor(injector: Injector, private formBuilder: FormBuilder, private srAdminService: ServiceRequestAdminService, private http: HttpClient,
              private cd: ChangeDetectorRef, private matDialog: MatDialog, private toastr: ToastrService, private datePipe: DatePipe,
              private translatePipe: ErrorLabelPipe, public mediaObserver: MediaObserver, private csrDataService: CsrDataService) {
    super(injector);
    this.resetSearchForm();
  }

  ngOnInit(): void {
    this.showPageLoader = true;
    this.editorOpt = new JsonEditorOptions();
    this.editorOpt.modes = ['code', 'tree', 'view'];
    this.editorOpt.mode = 'view';
    this.editorOpt.enableTransform = false;
    // this.getServiceRequestForms();
    this.groupMemberMap = new Map();
    this.getCSRGroupMembers();
    this.getMyGroups();
    // check if the logged user is a super account user

    this.formListDS = new MatTableDataSource<any>([]);
    this.formListDS.sortingDataAccessor = sortingDataAccessor.nestedProperty;

    this.route.paramMap.subscribe(params => {
      const deepLink = params.get('selectedTab');
      if (deepLink != null) {
        if (deepLink === 'all') {
          this.activeTab = this.tablinks[0];
        } else if (deepLink === 'myqueue') {
          this.activeTab = this.tablinks[1];
        }
      } else {
        this.activeTab = this.tablinks[0];
      }
      this.changeTabView(this.activeTab);
    });

    if (this.mediaObserver.isActive('lt-md')) {
      this.showFullMatTable = !this.showFullMatTable;
    }
  }

  ngAfterViewInit(): void {
    if (this.formListDS) {
      this.formListDS.sort = this.sort;
    }
    this.formListDS.paginator = this.formsPaginator;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    if (this.mediaObserver.isActive('lt-md')) {
      this.showFullMatTable = !this.showFullMatTable;
    }
  }

  resetData(completeReset?: boolean): void {
    const openrequests = this.activeTab === 'All Requests';
    this.selectedQlink = StatisticsProfile.of('All', null);
    this.selectedFilter = 'All';
    this.searchFilter = 'All';

    this.allForms = null;
    this.qLinks = [];
    if (completeReset) {
      this.formsFilterForm.reset();
    } else {
      this.resetSearchForm();
    }
    this.quickSearch.reset();

    this.selectedDataFilter = new DataFilter('All');
    this.attributeDataFilter = null;
    this.attributeDataFilterValue = 'All';
    this.attributeDataFilterValueList = new Array<string>();
    this.attributeDataFilterValueMap.clear();

    this.formDataAttributes = openrequests ? this.formAttributeConfig : this.formAttributeConfigMQ;
    this.displayedColumns = _.union(['index'], _.pluck(this.formDataAttributes, 'name'));

    this.applyDataFilterFunction();
  }

  changeTabView(tab: string, onClickResetBtn?: boolean): void {

    this.statusList = this.archiveSelected ? this.archiveStatusList : this.currentStatusList;
    this.activeTab = tab;
    const openrequests = this.activeTab === 'All Requests';
    this.showPageLoader = true;

    if (this.formListDS){
      this.formListDS.data = [];
    }

    this.resetData(onClickResetBtn);
    if (this.selectedFilterType === 'Status') {
      this.formsFilterForm.controls['status'].setValue([RequestStatus.Submitted.value]);
    } else {
      this.formsFilterForm.controls['status'].setValue([RequestStatus.All.value]);
    }

    const dayBeforeWeek = moment.tz(this.localtz).subtract(7, 'day').startOf('day');
    this.getFormsCountByTags(openrequests);
    this.populateRequestFilter();
  }

  getFullServiceAddress(serviceAddr: any): string {
    let address = '';
    if (serviceAddr) {
      address += serviceAddr.unitNumber ? ('Unit # ' + serviceAddr.unitNumber + ', ') : '';
      address += (serviceAddr.streetNumber || '') + ' ' + serviceAddr.streetName;
      address += serviceAddr.city ? (', ' + serviceAddr.city) : '';
      address += serviceAddr.province ? (', ' + serviceAddr.province) : '';
      address += serviceAddr.postalCode ? (' ' + serviceAddr.postalCode) : '';
    }
    return address.length > 0 ? address : null;
  }

  getFormsCountByStatus(openrequests: boolean): void {
    this.qLinks = new Array();
    this.srAdminService.countRequestsByStatus(openrequests, this.archiveSelected, 'MyAccount', 'N').subscribe(
      (res: any) => {
        console.log('test res::', res);
        const totalCount = _.reduce(res, (memo, data) => memo + (data.name === 'COMPLETED' && !this.archiveSelected ? 0 : data.count), 0);
        const statusMap = new Map<string, StatisticsProfile>();
        _.each(res, (stat: StatisticsProfile) => {
          stat.displayName = RequestStatus.getStatus(stat.name).resourceLabel;
          statusMap.set(stat.name, stat);
        });
        const sAll = StatisticsProfile.of('All', totalCount);
        sAll.displayName = 'All - ' + (this.archiveSelected ? 'Processed' : 'Pending');
        statusMap.set(RequestStatus.All.value, sAll);
        _.each(this.statusList, (stat: Status) => {
          if (statusMap.has(stat.value)) {
            this.qLinks.push(statusMap.get(stat.value));
          } else {
            const newS = StatisticsProfile.of(stat.resourceLabel, 0);
            statusMap.set(stat.value, newS);
            this.qLinks.push(newS);
          }
        });
        this.selectedQlink = statusMap.get(RequestStatus.All.value);
        this.applyFormsFilter(this.selectedQlink, false);
        console.log('this.qLinks:::', this.qLinks);
      },
      (error: any) => {
        this.showPageLoader = false;
        console.log('error getFormsCountByType', error);
      }
    );
  }

  getFormsCountByType(openrequests: boolean): void {
    this.srAdminService.countRequestsByType(openrequests, this.archiveSelected, 'MyAccount', 'N').subscribe(
      (res: any) => {
        const totalCount = _.reduce(res, (memo, data) => memo + data.count, 0);
        this.qLinks = _.union([StatisticsProfile.of('All', totalCount)], res);
        this.qLinks[0].displayName = 'All - ' + (this.archiveSelected ? 'Processed' : 'Pending');
        this.selectedQlink = this.qLinks[0];
        console.log('this.qLinks:::', this.qLinks, this.selectedQlink);
        this.cd.detectChanges();
      },
      (error: any) => {
        this.showPageLoader = false;
        console.log('error getFormsCountByType', error);
      }
    );
  }

  getFormsCountByName(openrequests: boolean): void {
    this.srAdminService.countRequestsByName(openrequests, this.archiveSelected, 'MyAccount', 'N').subscribe(
     // this.countRequestsByName().subscribe(
      (res: any) => {
        const totalCount = _.reduce(res, (memo, data) => memo + data.count, 0);
        this.qLinks = _.union([StatisticsProfile.of('All', totalCount)], res);
        this.qLinks[0].displayName = 'All - ' + (this.archiveSelected ? 'Processed' : 'Pending');
        this.selectedQlink = this.qLinks[0];
        this.getServiceRequestForms();
        this.applyFormsFilter(this.selectedQlink, false);
        this.cd.detectChanges();
      },
      (error: any) => {
        this.showPageLoader = false;
        console.log('error getFormsCountByName', error);
      }
    );
  }

  getFormsCountByTags(openrequests: boolean): void {
    this.srAdminService.countRequestsByTags(openrequests, this.archiveSelected, 'MyAccount', 'N').subscribe(
     // this.countRequestsByTags().subscribe(
      (res: StatisticsProfile[]) => {
        const totalCount = _.reduce(res, (memo, data) => memo + data.count, 0);
        res = _.union([StatisticsProfile.of('All', totalCount)], res);
        const tagMap: Map<string, StatisticsProfile> = new Map();
        _.each(res, (sp: StatisticsProfile) => {
          const pkeys = (sp.name || 'Others').split(',');
          _.each(pkeys, (pkey: string) => {
            if (tagMap.has(pkey)) {
              tagMap.get(pkey).count += sp.count;
            } else {
              tagMap.set(pkey, StatisticsProfile.of(pkey, sp.count));
            }
          });
        });
        this.tagLinks = Array.from(tagMap.values());
        this.selectedTaglink = this.tagLinks[0];
        console.log('this.qLinks:::', this.qLinks, this.selectedQlink);
      },
      (error: any) => {
        this.showPageLoader = false;
        console.log('error getFormsCountByName', error);
      }
    );
  }

  populateRequestFilter(): void {
    console.log('actionRequestFilter:::', this.selectedFilterType);
    const openrequests = this.activeTab === 'All Requests';
    this.qLinks = [];
    if (this.selectedFilterType === 'Name') {
      this.getFormsCountByName(openrequests);
    } else if (this.selectedFilterType === 'Status') {
      this.getFormsCountByStatus(openrequests);
    } else if (this.selectedFilterType === 'Category') {
      this.getFormsCountByType(openrequests);
    }
  }

  populateFormInfo(): void {
    if (this.formMap && this.formMap.size > 0 && !_.isEmpty(this.allForms)) {
      _.each(this.allForms, (csform: ServiceRequestQueue) => {
        csform.form = this.formMap.get(csform.formName);
      });
      console.log('Searched forms::::', this.allForms);
    }
    this.formListDS.data = this.applyFilterRule(this.allForms);
  }

  private applyFilterRule(rawDataList: Array<any>): Array<any> {
    let filteredDataList = null;
    let filteredRawList = rawDataList;
    if (this.attributeDataFilter && this.attributeDataFilterValue && this.attributeDataFilterValue !== 'All') {
      const lthis = this;
      filteredRawList = _.filter(filteredRawList, (tData: ServiceRequestQueue) => {
        return tData.getDeepData(lthis.attributeDataFilter.name) === lthis.attributeDataFilterValue;
      });
    }
    if (this.selectedDataFilter != null && this.selectedDataFilter.filter !== null) {
      const filterRules = this.selectedDataFilter.filter;
      filteredDataList = _.filter(filteredRawList, (tData: ServiceRequestQueue) => {
        let isPassed = true;
        _.each(filterRules, (filter: FilterRule) => {
          let attValue = tData.getDeepData(filter.attribute);
          if (_.isUndefined(attValue)) {
            attValue = null;
          }
          if (filter.condition === 'not') {
            isPassed = isPassed && (attValue !== null);
          } else if (filter.condition === 'is') {
            isPassed = isPassed && attValue === filter.value;
          } else if (filter.condition === 'gt') {
            isPassed = isPassed && +attValue > filter.value;
          } else if (filter.condition === 'lt') {
            isPassed = isPassed && +attValue < filter.value;
          } else if (filter.condition === 'ge') {
            isPassed = isPassed && +attValue >= filter.value;
          } else if (filter.condition === 'le') {
            isPassed = isPassed && +attValue <= filter.value;
          } else if (filter.condition === 'contains') {
            isPassed = isPassed && (attValue.indexOf(filter.value) >= 0);
          } else if (filter.condition === 'exclude') {
            isPassed = isPassed && (attValue.indexOf(filter.value) < 0);
          }
        });
        return isPassed;
      });
    }
    return filteredDataList || filteredRawList;
  }

  applyFormsFilter(qlink: StatisticsProfile, searchByTag: boolean = false): void {
    if (qlink.count !== 0) {
      this.expandAllSubRow = false;
      console.log('qLinkFilter:::', qlink);
      this.resetSearchForm();
      if (qlink) {
        if (searchByTag) {
          this.selectedTaglink = qlink;
        } else {
          this.selectedQlink = qlink;
        }
      }

      let qFilterValue = null;
      let fAttribute = null;

      // searchTag
      if (this.selectedTaglink === null || this.selectedTaglink?.name === 'All') {
        qFilterValue = null;
      } else {
        qFilterValue = (this.selectedTaglink?.name === 'NA') ? null : this.selectedTaglink?.name;
      }
      fAttribute = 'searchTag';
      this.formsFilterForm.controls[fAttribute].setValue(qFilterValue);

      // view request by
      if (this.selectedQlink === null || this.selectedQlink.name === 'All' || this.selectedQlink.name === 'ALL') {
        qFilterValue = null;
      } else {
        qFilterValue = (this.selectedQlink.name === 'NA') ? null : this.selectedQlink.name;
      }
      if (this.selectedFilterType) {
        if (this.selectedFilterType === 'Name') {
          fAttribute = 'formName';
        } else if (this.selectedFilterType === 'Status') {
          fAttribute = 'status';
        } else if (this.selectedFilterType === 'Category') {
          fAttribute = 'category';
        }
        this.formsFilterForm.controls[fAttribute].setValue(qFilterValue);
      }
      this.getCustomerServiceRequests();
    } else {
      this.showPageLoader = false;
    }
  }

  private resetSearchForm(): void {
    this.formsFilterForm = this.formBuilder.group({
      status: [['All']],
      category: [''],
      emailAddress: [''],
      requestedOnBefore: [''],
      requestedOnAfter: [''],
      formName: [''],
      searchTag: [''],
      requestNo: [''],
      assignedTo: [''],
      dueDate: ['']
    });
  }

  getCustomerServiceRequests(): void {
    this.showPageLoader = true;
    console.warn('formsFilterForm::::', this.formsFilterForm.value);
    const params = new URLSearchParams();
    const formValue = this.formsFilterForm.value;
    if (formValue.requestedOnBefore) {
      formValue.requestedOnBefore = moment.tz(formValue.requestedOnBefore, this.localtz).format('YYYY-MM-DD');
    }
    if (formValue.requestedOnAfter) {
      formValue.requestedOnAfter = moment.tz(formValue.requestedOnAfter, this.localtz).format('YYYY-MM-DD');
    }

    if (formValue.dueDate) {
      formValue.dueDate = moment.tz(formValue.dueDate, this.localtz).format('YYYY-MM-DD');
    }
    let queryParamsCount = 0;
    Object.keys(formValue).forEach((key: any) => {
      console.log('test::::', formValue[key], typeof (formValue[key]));
      const val = formValue[key];
      if (val) {
        params.append(key, formValue[key].toString().trim());
        queryParamsCount++;
      }
    });

    if(this.archiveSelected && ((queryParamsCount == 1 && params.get('status') === 'All') || queryParamsCount === 0)) {
      params.append('requestedOnAfter', this.getFormattedDate(this.getDefaultStartDate(-100).toDate()));
      params.append('requestedOnBefore', this.getFormattedDate(this.getTodaysDate().toDate()));
      this.showDisclaimerText = true; 
    }
    else {
      this.showDisclaimerText = false;
    }

    const openrequests = this.activeTab === 'All Requests';
    this.srAdminService.getCustomerServiceRequests(openrequests, this.archiveSelected, 'MyAccount', 'N', params ? params.toString() : '')
    .subscribe(
      (res: any) => {
        res = _.sortBy(res, function (request: ServiceRequestQueue) {
          return request.requestNo;
        }).reverse();
        _.each(res, (element, index) => {
          if (element && element.requestedOn){
            element.requestedOn = moment.utc(element.requestedOn).toDate();
          }
          if (element && element.assignedOn){
            element.assignedOn = moment.utc(element.assignedOn).toDate();
          }
          if (element && element.dueDate){
            element.dueDate = moment.utc(element.dueDate).toDate();
          }
          if (element && element.completedDate){
            element.completedDate = moment.utc(element.completedDate).toDate();
          }
          element['rowIndex'] = (index + 1);
          element['isSelected'] = false;
          try {
            element['requestDetails'] = element.requestInfo;
          } catch (e) { }
          if (element['requestDetails']) {
            if(element['requestDetails']['accountId']){
              element['accountId'] = element['requestDetails']['accountId']
            }
            if (element['requestDetails']['service_address_segments'] || element['requestDetails']['service_address']) {
              const service_address = element['requestDetails']['service_address_segments'] || element['requestDetails']['service_address'];
              element['currentServiceAddress'] = this.getFullServiceAddress(service_address);
            }
            else if (element['requestDetails']['new_service_address']) {
              element['currentServiceAddress'] = this.getFullServiceAddress(element['requestDetails']['new_service_address']);
            } else if (element['requestDetails']['current_account_with_my_oakville_hydro_profile'] && element['requestDetails']['current_account_with_my_oakville_hydro_profile']['Service-address']) {
              //this check is meant for link-multiple-accounts form
              element['currentServiceAddress'] = this.getFullServiceAddress(element['requestDetails']['current_account_with_my_oakville_hydro_profile']['Service-address']);
            } else if (element['requestDetails']['current_account_with_my_profile'] && element['requestDetails']['current_account_with_my_profile']['Service-address']) {
              //this check is meant for link-multiple-accounts form
              element['currentServiceAddress'] = this.getFullServiceAddress(element['requestDetails']['current_account_with_my_profile']['Service-address']);
            }
            else {
              // this check is meant for update-account-information form
              _.each(element['requestDetails'], (res, index) => {
                if (index.toString().includes('customer_information')) {
                  _.each(res, (data, index) => {
                    if (index.toString().includes('address')) {
                      if (data instanceof Object) {
                        element['currentServiceAddress'] = this.getFullServiceAddress(data);
                      } else {
                        element['currentServiceAddress'] = data;
                      }
                    }
                  });
                }
              });
            }
          }
          element['rowExpanded'] = false;
          element['addressForView'] = '';
        });
        this.allForms = res;
        this.showPageLoader = false;
        
        this.populateFormInfo();
        if (this.showPageLoader) {
          this.showPageLoader = false;
        }
      },
      (error: any) => {
        this.showPageLoader = false;
        this.allForms = null;
        console.log('error getCustomerServiceRequests', error);
      }
    );
  }

  dateChangeHandler(dateFormControl: string): void {
    console.log('viewPeriodChangeHandler:::', this.formsFilterForm.controls[dateFormControl]);
  }

  navigateToFormView(formRequestId: string): void {
    const formUrl = environment.customerFormsUrl + '/openform/' + formRequestId;
    console.log('Navigating to view form:', formUrl);
    window.open(formUrl);
  }

  viewUploadedFiles(formRequest: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + formRequest.requestId;
    dialogConfig.width = '600px';
    dialogConfig.data = JSON.parse(formRequest.uploadedFiles);

    const fileViewer = this.matDialog.open(FileViewerComponent, dialogConfig);
    fileViewer.afterClosed().subscribe((result: any) => {
      console.log('The file viewer dialog closed..:', result);
    });
  }

  viewRequest(formRequest: any): void {
    console.log(formRequest);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + formRequest.requestId;
    dialogConfig.width = '920px';
    const gMemberList = this.populateGroupAccessList(formRequest, true);
    dialogConfig.data = { formdata: formRequest, gMemberList,
      formHasGrpConflict: this.formHasGrpConflict, superAccountUser: this.superAccountUser };

    const detailView = this.matDialog.open(RequestDetailViewComponent, dialogConfig);
    detailView.afterClosed().subscribe(() => {
      const formModifiedInstance = detailView.componentInstance.formModified;
      console.log('The form detail dialog closed.. formModified:', formModifiedInstance);
      if (formModifiedInstance) {
        this.changeTabView(this.activeTab);
      }
    });
  }

  toggleAdvSearch(): void {
    this.advSearchOn = !this.advSearchOn;
  }
  toggleMatTable(): void {
    this.showFullMatTable = !this.showFullMatTable;
  }
  toggleFilter(): void {
    this.showSideNav = !this.showSideNav;
    console.log('sidenav', this.showSideNav);
  }

  private assignRequest(formRequestId: any, userId?: string): void {
    if (formRequestId instanceof Map){
      formRequestId = Array.from(formRequestId.values());
    }else{
      formRequestId = [formRequestId];
    }
    console.log('assignRequest :', formRequestId);
    console.log('userId :', userId);
    this.csrDataService.assignFormRequest(formRequestId, userId).subscribe(
      (res: any) => {
        console.log('assignRequest:::', res);
        this.changeTabView(this.activeTab);
        this.selection = new SelectionModel<any>(true, []);
        const successMsg = this.superAccountUser && userId !== this.loginUser.id ? 'Assigned to the designated CSR\'s Queue' : 'Assigned to your Queue';
        this.toastr.success(successMsg, 'Success', {
          timeOut: 10000,
        });
      },
      (error: any) => {
        console.log('error assignRequest', error);
        this.toastr.error('Error during request assign operation', 'Error', {
          timeOut: 10000,
        });
      }
    );
  }

  getCSRGroupMembers(groupName?: string): void {
    if (!groupName) {
      groupName = 'ALL';
    }
    this.csrDataService.getCSRGroupMembers(groupName).subscribe((userList: User[]) => {
      if (userList) {
        this.groupMemberMap = _.groupBy(userList, user => user.groupName);
      }
      console.log('this.groupMemberMap::', this.groupMemberMap);
    });
  }

  getMyGroups(): void {
    this.csrDataService.getMyGroup().subscribe((res: any) => {
      _.each(res, (value: string, index) => {
        if (value.includes('super')) {
          this.superAccountUser = value;
        }
      });
      console.log('myGroups::', res, 'supperAccount::', this.superAccountUser);
    });
  }

  assignToUserViewer(eligibleGrpMembers: any, requestIds: any): void {
    if (eligibleGrpMembers){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'assign-user-modal-component';
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = eligibleGrpMembers;

    const assignToUserViewer = this.matDialog.open(AssignToUserDialogComponent, dialogConfig);
    assignToUserViewer.afterClosed().subscribe((response: any) => {
      if (response !== null) {
        console.log('assigned user modal closed::::', response);
        this.assignRequest(requestIds, response);
      }
    });
    }else if (!eligibleGrpMembers && !this.formHasGrpConflict){
        this.toastr.error('Unable to complete assign operation.', 'Error', {
          timeOut: 10000,
        });
    }else if (this.formHasGrpConflict){
      this.toastr.error(`Form Group Access Conflict - Selected forms have both CSR & Engineering access. Unable to assign!`, 'Error', {
          timeOut: 10000,
        });
    }
  }
  assignToMe(requests: any): void {

    const requestIds = [];
    if (requests && Array.isArray(requests)) {
       _.each(requests, (req: any) => {requestIds.push(req.requestId)});
    }
    else {
      requestIds.push(requests);
    }
    this.assignRequest(requestIds, this.loginUser.id);
  }

  unassign(formRequestId: string): void {
    console.log('unassignRequest :', formRequestId);
    this.srAdminService.unassign(formRequestId).subscribe(
      (res: any) => {
        console.log('unassignRequest:::', res);
        this.changeTabView(this.activeTab);
        this.toastr.success('The request has been removed from your Queue', 'Success', {
          timeOut: 10000,
        });
      },
      (error: any) => {
        console.log('error unassign', error);
        this.toastr.error('Error during request unassign operation', 'Error', {
          timeOut: 10000,
        });
      }
    );
  }

  getServiceRequestForms(): void {
    this.formMap = null;
    this.srAdminService.getAllServiceRequests(this.archiveSelected, 'MyAccount', 'N').subscribe(
    // this.getAllServiceRequests().subscribe(
      (res: any) => {
        this.allMasterForms = res;
        if (this.allMasterForms) {
          this.formMap = new Map();
          const lthis = this;
          _.each(this.allMasterForms, (form: ServiceRequest) => {
            if (form && form.enable){
               lthis.formMap.set(form.name, form);
            }
          });
        }
        this.populateFormInfo();

        if(this.selectedFilterType !== 'Status') {
          const formNames = [...this.formMap.values()];
          const qLinkMap = new Map<string, number>()
          _.each(this.qLinks, (item: StatisticsProfile) => {
            qLinkMap.set(item.name, 1);
          });
          _.each(formNames, (item: any) => {
            if (!qLinkMap.has(item.name)) {
              _.uniq(this.qLinks.push(StatisticsProfile.of(item.displayName, 0)));
            }
          });
        }
      },
      (error: any) => {
        console.log('error getCustomerServiceMasterData()', error);
      }
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    let statusLabel = '';

    this.formListDS.data.forEach((res, index) => {
      if(res && res.statusObject){
      if (this.translatePipe.transform(res.statusObject.resourceLabel, 'cis').toString().toLowerCase().includes(filterValue.toLowerCase())
      && filterValue.length > 0) {
        statusLabel = res.statusObject.resourceLabel;
      }
    }
    })
    if (statusLabel.length > 0) {
      this.formListDS.filter = statusLabel.toLowerCase();
    } else {
      this.formListDS.filter = filterValue.toLowerCase();
    }

    if (this.formListDS.paginator) {
      this.formListDS.paginator.firstPage();
    }
  }

  currentToArchiveFilter(): void{
    this.archiveSelected = !this.archiveSelected;
    this.changeTabView(this.activeTab);
  }

  toggleStatusAll(matSelect: MatSelect): void {
    const isAllSelected: boolean = matSelect.options
      // The "Select All" item has the value 0
      .filter((item: MatOption) => item.value === RequestStatus.All.value)
      // Get the selected property (this tells us whether Select All is selected or not)
      .map((item: MatOption) => item.selected)[0];
    // Get the first element (there should only be 1 option with the value 0 in the select)
    if (isAllSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleStatusSelection(): void {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }

    if (this.formsFilterForm.value.status?.length === this.statusList.length - 1 && !this.allSelected.selected) {
      this.allSelected.select();
    }
  }

  toggleRowExpansion(data): void {
    this.formListDS.filteredData.forEach(res => {
      if (res.requestNo !== data.requestNo) {
        res.rowExpanded = false;
      }
    });
    if (!data.requestDetails.banking_information && !data.currentServiceAddress && !data.requestDetails.account_information) {
      data.rowExpanded = false;
    } else if (data.formName === 'new-application-for-service-form') {
      data.rowExpanded = !data.rowExpanded;
    }
    this.expandAllSubRow = false;
  }

  expandAllRows(flag: boolean): void {
    this.expandAllSubRow = !flag;
    this.formListDS.filteredData.forEach(row => {
      if (!row.requestDetails.banking_information && !row.currentServiceAddress && !row.requestDetails.account_information) {
        row.rowExpanded = false;
      } else if (row.formName === 'new-application-for-service-form') {
        row.rowExpanded = this.expandAllSubRow;
      }
    });
  }

  exportToCSV(): void {
    console.log('this.formListDS.data', this.formListDS.data);
    const tableDataCSV = [];
    console.log('tableDatCSV', tableDataCSV);
    this.formListDS.data.forEach((element, ind) => {
      const configHeader = this.activeTab === 'My Queue' ? this.formAttributeConfigMQ : this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        if (attribute.type !== 'action') {
          let val = element.getDeepData(attribute.name);
          if (attribute.class === 'email') {
            val = element.getDeepData(attribute.secondaryData) || 'Unassigned'
          }
          if (attribute.translate) {
            val = this.translatePipe.transform(val, 'cis');
          }
          if (attribute.type === 'date') {
            val = this.datePipe.transform(val, 'mediumDate')
          }

          const key = attribute.label;
          tempObj[key] = val ? val : '';
        }
      }
      tableDataCSV.push(tempObj);
    });
    console.log('tableDatCSV after filter', tableDataCSV);
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Request_Export_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
 
  columnValueFilter(attribute: string, filterValue: string): void {
    this.formListDS.filter = 'filter:' + attribute + filterValue.toLowerCase();
  }

  multiFormAssign(): void{
    console.log(this.selection.selected);
    if (this.superAccountUser){
      this.assignToOthersWorkflow(this.selection.selected, false);
    }else{
      this.assignToMe(this.selection.selected);
    }
  }

  assignToOthersWorkflow(request: any, singleForm: boolean): void{
    const eligbleMembersList = this.populateGroupAccessList(request, singleForm);
    const selectedRequestIds = [];
    if (singleForm){
      selectedRequestIds.push(request.requestId);
    } else {
      _.each(request, (req: any) => {selectedRequestIds.push(req.requestId)})
    }
    this.assignToUserViewer(eligbleMembersList, selectedRequestIds);
  }

  populateGroupAccessList(data: any, singleForm: boolean): any {
    this.formHasGrpConflict = false;
    if (data) {
      let csrGroupAccess = false;
      let engineeringGroupAccess = false;
      if (singleForm && data.form && data.form.groupAccess) {
        csrGroupAccess = data.form.groupAccess.includes('csr');
        engineeringGroupAccess = data.form.groupAccess.includes('engineering');
      } else {
        csrGroupAccess = _.every(data, (selectedRequest: any) => {
          if (selectedRequest.form && selectedRequest.form.groupAccess) {
            return selectedRequest.form.groupAccess.includes('csr');
          }
        });

        engineeringGroupAccess = _.every(data, (selectedRequest: any) => {
          if (selectedRequest.form && selectedRequest.form.groupAccess) {
            return selectedRequest.form.groupAccess.includes('engineering');
          }
        });

        if (!csrGroupAccess && !engineeringGroupAccess) {
          console.log('formHasGrpConflict');
          this.formHasGrpConflict = true;
        }
      }
      console.log('containsCsrOrEngGroupAccess', csrGroupAccess, engineeringGroupAccess);


      const eligibleMembersList = csrGroupAccess ? this.roleCategoryMap.get('csr') : this.roleCategoryMap.get('engineering');
      if (!this.formHasGrpConflict && eligibleMembersList) {
        let groupAccessList = [];
        if (this.groupMemberMap[eligibleMembersList.general] && this.groupMemberMap[eligibleMembersList.super]) {
          groupAccessList = [...this.groupMemberMap[eligibleMembersList.general], ...this.groupMemberMap[eligibleMembersList.super]];
        } else if (this.groupMemberMap[eligibleMembersList.general]) {
          groupAccessList = [...this.groupMemberMap[eligibleMembersList.general]];
        } else if (this.groupMemberMap[eligibleMembersList.super]) {
          groupAccessList = [...this.groupMemberMap[eligibleMembersList.super]];
        }
        return groupAccessList;
      }
    }
  }

  private applyDataFilterFunction(): void {
    const lthis = this;
    this.formListDS.filterPredicate = (data: ServiceRequestQueue, filter: string): boolean => {
      if (filter && filter.length > 0) {
        filter = filter.trim();
        if (filter.indexOf('filter:') >= 0) {
          filter = filter.substring(filter.indexOf('filter:') + 7);
          const fcondition = _.find(lthis.filterConditions, (condition: string) => {
            return filter.indexOf(condition) > 0;
          });
          if (fcondition && fcondition != null) {
            const fKeyValue = filter.split(fcondition);
            if (fKeyValue && fKeyValue.length === 2) {
              const dataValue = +data.getDeepData(fKeyValue[0].trim());
              const dataFilter = +fKeyValue[1].trim();
              if (fcondition === '>=') {
                return dataValue >= dataFilter;
              } else if (fcondition === '>') {
                return dataValue > dataFilter;
              } else if (fcondition === '<=') {
                return dataValue <= dataFilter;
              } else if (fcondition === '<') {
                return dataValue < dataFilter;
              } else {
                return dataValue === dataFilter;
              }
            }
          }
          return true;
        }
        let dataAsString = '';
        _.each(lthis.formDataAttributes, (attribute: any) => {
          dataAsString += (attribute.name + ':' + data.getDeepData(attribute.name) + ',');
        });
        if (filter.indexOf(',') >= 0) {
          let filterKeys = filter.split(',');
          filterKeys = _.filter(filterKeys, (filterKey: any) => filterKey !== null && filterKey.trim().length > 0);
          let hasPassed = false;
          _.each(filterKeys, (filterKey: any) => {
            hasPassed = hasPassed || (dataAsString.toLowerCase().indexOf(filterKey.trim()) >= 0);
          });
          return hasPassed;
        } else {
          return dataAsString.toLowerCase().indexOf(filter) >= 0;
        }
      }
      return true;
    };
  }

  getFormattedDate(date: any) {
    if (date) {
      return moment.tz(date, this.localtz).format('YYYY-MM-DD');
    }
  }

  getDefaultStartDate(bias?: number) {
    if(bias) {
      return this.getTodaysDate().add(bias, 'days');
    }
    else {
      return this.getTodaysDate().add(-7, 'days');
    }
  }

  getTodaysDate() {
    return moment();
  }

   // TODO: remove below code

   getAllServiceRequests(groupName?): Observable<any> {
    const apiUrl = `/assets/resources/mockdb/serviceRequests.json`;
    return this.http.get<any>(apiUrl).pipe(
      tap(res => {
        console.log('fetched My Group List:', res);
      })
    );

  }

  getCustomerServiceRequestsMock(): Observable<any> {
    const apiUrl = `/assets/resources/mockdb/requests.json`;
    return this.http.get<any>(apiUrl).pipe(
      tap(res => {
        console.log('fetched My Group List:', res);
      })
    );

  }

  countRequestsByTags(groupName?): Observable<any> {
    const apiUrl = `/assets/resources/mockdb/countReqByTag.json`;
    return this.http.get<any>(apiUrl).pipe(
      tap(res => {
        console.log('fetched My Group List:', res);
      })
    );

  }

  countRequestsByName(groupName?): Observable<any> {
    const apiUrl = `/assets/resources/mockdb/countReqByName.json`;
    return this.http.get<any>(apiUrl).pipe(
      tap(res => {
        console.log('fetched My Group List:', res);
      })
    );

  }
  // removable code - end
}